// Button.js
import React from 'react';

function Button({ label }) {
    return (
        <button>{label}</button>
    );
}

export default Button;
