import React from 'react'
import OperatorWiseSale from '../../components/reportMaster/operatorWiseSale/OperatorWiseSale'

function OperatorWiseSalePage() {
  return (
    <>
      <OperatorWiseSale/>
    </>
  )
}

export default OperatorWiseSalePage
