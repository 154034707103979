import CommissionLab from "./commissionLab/CommissionLab"
import DistributerMoneyTrnsfer from "./distributerMoneyTransfer/DistributerMoenyTransfer"

function SetOperatorSettings() {
    return (
        <>
            <DistributerMoneyTrnsfer />
            <CommissionLab />
        </>
    )
}
export default SetOperatorSettings