import PayoutBeneficiaryMaster from "../../../components/topNavigationComp/masters/PayoutBeneficiaryMaster/PayoutBeneficiaryMaster"


function PayoutBeneficiaryMasterPage() {
    return (
        <>
            <PayoutBeneficiaryMaster />
        </>
    )
}
export default PayoutBeneficiaryMasterPage