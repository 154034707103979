import Area from "../../../../components/topNavigationComp/ZoneModule/area/Area"


function AreaPage() {
    return (
        <>
            <Area />
        </>
    )
}
export default AreaPage 