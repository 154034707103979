import SiteSettings from "../../../../components/topNavigationComp/settings/siteSetting/SiteSettings"


function SiteSettingPage() {
    return (
        <>
            <SiteSettings />
        </>
    )
}
export default SiteSettingPage