import React from 'react'
import CourseDegreeType from '../../../../components/topNavigationComp/masters/courseDegreeType/CourseDegreeType'

function Coursedegree() {
  return (
    <>
      <CourseDegreeType/>
    </>
  )
}

export default Coursedegree
