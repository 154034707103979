import React from 'react'
import ContactEnquiry from '../../../../components/topNavigationComp/masters/contactEnquiry/ContactEnquiry'

const ContactInquery = () => {
    return (
        <>
        <ContactEnquiry/>
        </>
    )
}

export default ContactInquery
