import BackupApiMasterList from "./backupApiMasterList/backupApiMasterList"


function BackupApiMaster() {
    return (
        <>
            <BackupApiMasterList />
        </>
    )
}
export default BackupApiMaster