import ApiSwitching from "../../../../components/topNavigationComp/apimaster/apiSwitch/ApiSwitching"


function ApiSwitchingPage() {
    return (
        <>
            <ApiSwitching />
        </>
    )
}
export default ApiSwitchingPage