import AgentOnbordingList from "./agentOnbordingList/AgentOnbordingList"


function AgentOnbording() {
    return (
        <>

            <AgentOnbordingList />

        </>
    )
}
export default AgentOnbording