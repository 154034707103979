import React from 'react'
import WhatsappRoleWise from '../../../components/topNavigationComp/whatsappRoleWise/WhatsappRoleWise'
import Breadcrumbs from '../../../common/breadcrumb/Breadcrumbs'

const SendRoleWisePage = () => {
  return (
    <>
    <Breadcrumbs breadCrumbsTitle={''}/>
      <WhatsappRoleWise/>
    </>
  )
}

export default SendRoleWisePage
