import NotificationsSetting from "../../../../components/topNavigationComp/settings/notificationSettings/NotificationSettings"


function NotificationSettingsPage() {
    return (
        <>
            <NotificationsSetting />
        </>
    )
}
export default NotificationSettingsPage