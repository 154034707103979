import React from 'react'
import ResetPassword from '../../components/resetPassword/ResetPassword'

function LoginPasswordReset() {
    return (
        <>
            <ResetPassword />
        </>
    )
}

export default LoginPasswordReset
