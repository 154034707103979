import React from 'react'
import CourseDegreeTypeList from './courseDegreeTypeList/CourseDegreeTypeList'

function CourseDegreeType() {
  return (
    <>
      <CourseDegreeTypeList/>
    </>
  )
}

export default CourseDegreeType
