import React from 'react'
import CollegesData from '../../components/collegesData/CollegesData'

function CollagesData() {
  return (
    <>
      <CollegesData/>
    </>
  )
}

export default CollagesData
