import React from 'react'
import PriorityMaster from '../../../components/dmtModule/priorityMaster/PriorityMaster'

function PriorityPages() {
  return (
    <>
      <PriorityMaster/>
    </>
  )
}

export default PriorityPages
