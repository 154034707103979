import UserPannelSlider from "../../../../components/topNavigationComp/websiteMaster/userPannelSlider/UserPannelSlider"


function UserPannelSliderPage() {
    return (
        <>
            <UserPannelSlider />
        </>
    )
}
export default UserPannelSliderPage