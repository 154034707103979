import React from 'react'
import PriorityMasterList from './PriorityMasterlist/PriorityMasterList'

function PriorityMaster() {
  return (
    <>
      <PriorityMasterList/>
    </>
  )
}

export default PriorityMaster
