import React from 'react'
import LeadSourceMaster from '../../components/topNavigationComp/masters/leadSourceMaster/LeadSourceMaster'

function LeadSourceMasterPage() {
  return (
    <>
      <LeadSourceMaster/>
    </>
  )
}

export default LeadSourceMasterPage
