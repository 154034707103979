import NotWorkingUser from "../../components/notWorkingUser/NotWorkingUser"


function NotWorkingUserPage() {
    
    return (
        <>
            <NotWorkingUser />
        </>
    )
}
export default NotWorkingUserPage