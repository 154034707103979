import DynemicPageList from "./dynemicPageList/DynemicPageList"


function Dynemic() {
    return (
        <>
            <DynemicPageList />
        </>
    )
}
export default Dynemic