
function PermisionOption() {
    return <div className="container mt-4">
        <div className="row">
            <div className="col-12">
                <div className="card p-3">
                    <div className="row">
                        <div className="col-12 mb-2">
                            <p><strong>Whatsapp: </strong>YES </p>
                        </div>
                        <div className="col-12 mb-2">
                            <p><strong>SMS: </strong> YES</p>
                        </div>
                        <div className="col-12 mb-2">
                            <p><strong>Email Optin	: </strong> YES</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default PermisionOption;