import React from 'react'
import ApprovalMasters from '../../components/topNavigationComp/masters/approvalMaster/ApprovalMasters'

function ApprovalMaster() {
  return (
    <>
    <ApprovalMasters/>
    </>
  )
}

export default ApprovalMaster
