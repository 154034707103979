import UploadServiceBanner from "./uploadServiceBanner/UploadServiceBanner"
import UploadServiceBannerList from "./uploadServiceBannerList/UploadServiceBannerList"

function ServiceBanner() {
    return (
        <>
            <UploadServiceBanner />
            <UploadServiceBannerList />
        </>
    )
}
export default ServiceBanner