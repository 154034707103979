import RoleMasterList from "../../../../components/topNavigationComp/masters/roleMaster/roleMasterList/RoleMasterlist"


function RoleMasterPage() {
    return (
        <>
            <RoleMasterList />
        </>
    )
}
export default RoleMasterPage