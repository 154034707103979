import SuspendedUsers from "../../components/suspendedUsers/SuspendedUser"


function SuspendedUserPage() {
    return (
        <>
            <SuspendedUsers />
        </>
    )
}
export default SuspendedUserPage