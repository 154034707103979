import GuestResistrationSerttings from "./guestResistrationSerttings/GuestResistrationSerttings"
import TopSiteSetting from "./topSiteSetting/TopSiteSetting"


function SiteSettings() {
    return (
        <>
            <TopSiteSetting />
            <GuestResistrationSerttings />
        </>
    )
}
export default SiteSettings