import AddComplane from "../../../components/createCompalne/addComplane/AddComplne"


function AddCompalnePage() {
    return (
        <>
            <AddComplane />
        </>
    )
}
export default AddCompalnePage