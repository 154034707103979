import React from 'react'
import SetGroupAttributes from '../../components/setGroutAtrributes/SetGroupAttributes'

function SetGroupAttributesPages() {
  return (
    <>
      <SetGroupAttributes/>
    </>
  )
}

export default SetGroupAttributesPages
