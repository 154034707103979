import AepsStatement from "./aepsStatement/AepsStatement"
import Search from "./search/Search"


function Aeps() {
    return (
        <>
            <Search />
            <AepsStatement />
        </>
    )
}
export default Aeps