import React from 'react'

function ListGroupsSetAttributes() {
  return (
    <div>
      list attributes
    </div>
  )
}

export default ListGroupsSetAttributes
