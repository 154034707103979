import React from 'react'
import PackgesListSales from '../../components/PackageSaleReplort/PackgesList'

function PackageSaleReplort() {
  return (
    <>
      <PackgesListSales/>
    </>
  )
}

export default PackageSaleReplort
