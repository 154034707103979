import React from 'react'

import ComplaintsDashboard from '../../components/complaintsDashboard/ComplaintsDashboard';

function ComplaintsDashboardPages() {
    return (
        <div>
            <ComplaintsDashboard />
        </div>
    )
}

export default ComplaintsDashboardPages
