import { Empty, Pagination, Popconfirm } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { baseUrlImage } from '../../../../../baseUrl'

function PhotoGalleryMasterList({ data, totalCount, page, count, onChangeVal, confirm, cancel }) {
    return (
        <>
            <div>
                <div className="row m-2">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1">
                                    <div className="tbl-caption">
                                        <h4 className="heading mb-0"> Gallery
                                        </h4>
                                        <div>
                                            <Link className="btn btn-primary btn-sm" to="/create-gallery" role="button" aria-controls="offcanvasExample">+ CREATE Gallery</Link>
                                            {/* <button type="button" className="btn btn-secondary btn-sm" >
                                        + Invite Employee
                                    </button> */}
                                        </div>
                                    </div>
                                    <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer"><div className="dt-buttons"><button className="dt-button buttons-excel buttons-html5 btn btn-sm border-0" tabIndex={0} aria-controls="empoloyees-tblwrapper" type="button"><span><i className="fa-solid fa-file-excel" /> Export Report</span></button> </div><table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                        <thead>
                                            <tr role="row">

                                                <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '60.45px' }}>
                                                    S.No
                                                </th>
                                                <th className="sorting text-center" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '203.45px' }}>
                                                    Gallery
                                                </th>

                                                <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '156.475px' }}>
                                                    Status</th>

                                                <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '96.125px' }}>
                                                    Actions </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.length === 0 ? (
                                                <tr>
                                                    <td colSpan="4"><Empty className='m-0' image={Empty.PRESENTED_IMAGE_SIMPLE} /></td>
                                                </tr>
                                            ) : (
                                                data?.map((item, i) => (
                                                    <tr role="row" className="odd" key={i}>
                                                        <td>{(i + 1) + (page * count)}</td>
                                                        <td>
                                                            {
                                                                item?.image ? (<div className='image'>
                                                                    <picture>
                                                                        <img src={`${baseUrlImage}${item?.image}`} alt='profile_pic' style={{ width: "100%", height: "80px", borderRadius: "14px", objectFit: "contain",overflow:'hidden' }} />
                                                                    </picture>
                                                                </div>) : (<div className='border p-2 rounded'><img id="avatar-image" src="https://lh5.googleusercontent.com/proxy/t08n2HuxPfw8OpbutGWjekHAgxfPFv-pZZ5_-uTfhEGK8B5Lp-VN4VjrdxKtr8acgJA93S14m9NdELzjafFfy13b68pQ7zzDiAmn4Xg8LvsTw1jogn_7wStYeOx7ojx5h63Gliw" alt="Profile Picture" style={{ width: "100%", height: "80px", borderRadius: "14px", objectFit: "contain",overflow:'hidden' }} /></div>)
                                                            }
                                                        </td>
                                                        <td>
                                                            <span
                                                                className="badge badge-success text-light border-0"
                                                                style={{
                                                                    backgroundColor: `${item?.isActive === true ? 'blue' : '#bc3922ab'}`,
                                                                    fontSize: `${item?.isActive === false ? '0.8rem' : ''}`
                                                                }}
                                                            >
                                                                {item?.isActive === true ? 'Active' : 'Inactive'}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <Link to={`/update-gallery/${item?._id}`} className="btn btn-primary shadow btn-xs sharp me-1">
                                                                    <i className="fa fa-pencil" />
                                                                </Link>
                                                                <Popconfirm
                                                                    title="Delete gallery!"
                                                                    description="Are you sure you want to delete?"
                                                                    onConfirm={() => confirm(item?._id)}
                                                                    onCancel={cancel}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Link to="#" className="btn btn-danger shadow btn-xs sharp">
                                                                        <i className="fa fa-trash" />
                                                                    </Link>
                                                                </Popconfirm>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}


                                        </tbody>
                                    </table>
                                        < div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">
                                            Total {totalCount} entries
                                        </div>
                                        <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                            <Pagination
                                                // showSizeChanger
                                                // onShowSizeChange={''}

                                                defaultCurrent={1}
                                                onChange={onChangeVal}
                                                total={totalCount}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
            <ToastContainer className="text-center" />
        </>
    )
}

export default PhotoGalleryMasterList
