import React from 'react'
import Users from './users/Users'
import Breadcrumbs from '../../../common/breadcrumb/Breadcrumbs'

function Customer() {
  return (
    <>
    <Breadcrumbs breadCrumbsTitle={""}/>
      <Users/>
    </>
  )
}

export default Customer
