import SolveDispute from "../../components/solveDispute/SolveDispute"


function SolveDisputePage() {
    return (
        <>
            <SolveDispute />
        </>
    )
}
export default SolveDisputePage