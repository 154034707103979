import React from 'react'
import City from '../../components/city/City'

function Cities() {
  return (
    <>
      <City/>
    </>
  )
}

export default Cities
