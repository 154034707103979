import React from 'react'
import DmtStatus from '../../../components/dmtModule/status/DmtStatus'

function DmtStatusPage() {
  return (
    <>
      <DmtStatus/>
    </>
  )
}

export default DmtStatusPage
