import React from 'react'
import PackgesList from '../../components/packagemaster/PackgesList'

function PackageMasterPage() {
  return (
    <>
      <PackgesList/>
    </>
  )
}

export default PackageMasterPage
