import React from 'react'
import CityList from './cityList/CityList'

function City() {
  return (
    <>
      <CityList/>
    </>
  )
}

export default City
